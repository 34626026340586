<template>
	<router-link :to="{ name: 'channelPage', params: { address: item.address }}">
		<v-hover v-slot:default="{ hover }">
			<v-img
				:src="getPhotoPath('channel', item.id) + item.photo + '_150.jpg'"
				:srcset="getPhotoPath('channel', item.id) + item.photo + '.jpg 2x'"
				:alt="item.title_rus"
				width="150"
				height="222"
			>
				<v-container fill-height text-center>
					<div class="channel-title">
						<span v-html="item.title_rus" />
						<span class="year" v-if="showYear || item.section == 'movie'" v-text="' ' + item.year" />
					</div>
					<div v-if="hover || item.fave || item.subscribe">
						<div class="channel-overlay background" />
						<div class="channel-overlay">
							<div v-if="item.kinopoisk">
								<v-icon color="red">icon-star-half_empty</v-icon>
								<span class="rating-int">{{ ratingGetPart(item.kinopoisk, 0) }}</span>
								<template>
									<span
										class="rating-decimal"
										v-if="ratingGetPart(item.kinopoisk, 1)"
									>,{{ ratingGetPart(item.kinopoisk, 1) }}</span>
								</template>
							</div>
							<div>
								{{ item.year }}, {{ item.country }}
								<br />
								<span v-html="item.genre" />
								<br />
								{{ item.duration ? sToTimeHuman(item.duration) : '' }}
							</div>
						</div>
						<v-tooltip top v-if="item.id">
							<template v-slot:activator="{ on }">
								<v-btn
									icon
									class="fave"
									:class="item.fave ? 'in' : ''"
									small
									v-on="on"
									@click.prevent="onFave(item, 'fave')"
								>
									<v-icon color="red">icon-heart-empty</v-icon>
								</v-btn>
							</template>
							<span>{{ item.fave ? 'Удалить из избранного' : 'Добавить в избранное' }}</span>
						</v-tooltip>
						<v-tooltip top v-if="item.id">
							<template v-slot:activator="{ on }">
								<v-btn
									icon
									class="look_later"
									:class="item.subscribe ? 'in' : ''"
									small
									v-on="on"
									@click.prevent="onFave(item, 'subscribe')"
								>
									<v-icon color="green">icon-bookmark</v-icon>
								</v-btn>
							</template>
							<template v-if="!item.subscribe">
								<span v-if="item.section == 'movie'">Посмотреть позже</span>
								<span v-if="item.section == 'series'">Добавить в "мои сериалы"</span>
								<span v-if="item.section == 'anime'">Добавить в "мои аниме"</span>
							</template>
							<template v-else>
								<span>Удалить из списка просмотра</span>
							</template>
						</v-tooltip>
					</div>
				</v-container>
			</v-img>
		</v-hover>
	</router-link>
</template>
<script>
import { Helper } from "../../mixins/Helper";

export default {
	props: ["item", "showYear"],
	mixins: [Helper],
	data() {
		return {};
	},
	methods: {
		onFave: function(item, type) {
			window.ajax("/api/" + type + "/set/" + item.id, [], function(
				data,
				error
			) {
				//console.log(data);
				if (!error) {
					item[type] = data.status;
				}
			});
		},
		ratingGetPart(rating, part) {
			return (rating + "").split(".")[part];
		},
		sToTimeHuman(value) {
			let minutes = parseInt(Math.floor(value / 60));
			return (
				minutes +
				" " +
				this.countPlural(minutes, ["минута", "минуты", "минут"])
			);
		}
	}
};
</script>
<style scoped>
.v-image .container {
	background: linear-gradient(
		to bottom,
		rgba(0, 0, 0, 0) 0%,
		rgba(0, 0, 0, 0) 50%,
		rgba(0, 0, 0, 1) 100%
	);
	bottom: 0px;
	height: 222px;
}

.v-image:hover {
	box-shadow: 3px 3px 7px #777;
}

.v-image {
	box-shadow: 3px 3px 7px #bbb;
}

.channel-title {
	position: absolute;
	bottom: 10px;
	left: 10px;
	width: 130px;
	color: white;
	white-space: normal;
	line-height: 18px;
	font-size: 14px;
	z-index: 1;
}

.channel-overlay {
	position: absolute;
	top: 30px;
	left: 10px;
	width: 130px;
	padding-top: 3px;
	color: white;
	height: 120px;
	overflow: hidden;
	transition: opacity 0.3s ease-in-out;
	opacity: 0;
	z-index: 0;
	font-size: 14px;
}
.channel-overlay.background {
	top: 0px;
	left: 0;
	height: 100%;
	width: 100%;
}

.v-btn--icon {
	position: absolute;
	top: 0;
	right: 0;
}
.v-btn--icon:hover,
.v-btn--icon.in {
	background-color: white !important;
	opacity: 0.7;
	display: block;
}
.look_later {
	left: 0px;
	right: unset;
}
.v-image .container:hover .v-btn--icon {
	opacity: 1;
}

.v-image .container:hover .channel-overlay.background {
	opacity: 0.6;
	background-color: black;
}
.v-image .container:hover .channel-overlay {
	opacity: 1;
}

.v-image .container:hover .channel-title .year {
	display: none;
}

.rating-int {
	font-size: 20px;
}

@media only screen and (max-width: 640px) {
	.container {
		padding: 0px;
	}
}
</style>
