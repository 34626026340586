<template>
	<div>
		<v-dialog v-model="image_dialog" content-class="img_original">
			<template v-slot:activator="{ on, attrs }">
				<img
					:src="image"
					:srcset="image2x ? image2x + ' 2x' : false"
					:alt="title"
					style="cursor: pointer;"
					v-on="on"
					v-bind="attrs"
					class="preview"
				/>
			</template>
			<img
				:src="image_original"
				:alt="title"
				ref="channelOriginalImage"
				@click="image_dialog = false"
				@load="loaded"
			/>
			<v-btn fixed fab top right style="z-index: 300;" small @click.stop="image_dialog = false">
				<v-icon>icon-close</v-icon>
			</v-btn>
		</v-dialog>
	</div>
</template>
<script>
export default {
	props: ["image", "image2x", "title", "image_original"],
	data() {
		return {
			image_dialog: false
		};
	},
	watch: {
		image_dialog: function() {
			this.$nextTick(this.adjustWidth);
		}
	},
	methods: {
		loaded() {
			if (this.$refs.channelOriginalImage.style.maxHeight == "") {
				this.$refs.channelOriginalImage.style.maxHeight =
					document.querySelector(".img_original").clientHeight -
					100 +
					"px";
			}
			this.adjustWidth();
		},
		adjustWidth() {
			document.querySelector(".img_original").style.width =
				this.$refs.channelOriginalImage.clientWidth + "px";
		}
	}
};
</script>
<style scoped>
.v-dialog > img {
	width: unset;
}
.preview {max-width: 100%;}
</style>
<style>
.img_original {
	box-shadow: unset;
}
</style>
