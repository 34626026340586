export const Helper = {
	methods: {
		getAssetsDomain: function () {
			return "https://cdn1.atoto.ru/";
		},
		getPhotoPath: function (section, id) {
			let flat = section == 'set' || section == 'channel';
			return this.getAssetsDomain() + section + "/" + (!flat ? Math.floor(id / 1000) + "/" : "") + id + "/";
		},
		getVideoPhoto: function (key, preview, size) {
			let postfix = preview > 1 ? '_' + preview : '';
			return this.getAssetsDomain() + "video/" + key + "/" + (size ? size : '_') + postfix + ".jpg";
		},
		countPlural: function (number, titles) {
			let cases = [2, 0, 1, 1, 1, 2];
			return titles[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]];
		},
		getSubPath: function (sub, video) {
			if (!video.server && video.server_cache)
				return '//c' + video.server_cache + '.krasview.ru/' + video.v_key + '/' + sub.name;
			return '//media' + video.server + '.krasview.ru/video/' + video.v_key + '/' + sub.name;
		},
		humanDate(date) {
			if (!Number.isInteger(date)) return date;
			let date2 = new Date(date * 1000), formatter = new Intl.DateTimeFormat("ru", {
				year: "numeric",
				month: "long",
				day: "numeric"
			});
			return formatter.format(date2);
		},
		humanCurrency(value) {
			let formatter = new Intl.NumberFormat("ru", {
				style: "currency",
				currency: "RUB"
			});
			return formatter.format(value);
		},

		videoTitleFilter(title) {
			let re = new RegExp("^.*? - \\s*", "i");
			return title.replace(re, "");
		},

		getPersonName(person) {
			if (person.name_rus && person.name_orig)
				return person.name_rus + " / " + person.name_orig;
			if (person.name_rus) return person.name_rus;
			if (person.name_orig) return person.name_orig;
			return "";
		},

		getVoice(video) {
			const info = video.extra.audio_info;
			if (!info) return '';
			let out = [];
			for (let i = 0; i < video.audio; i++) {
				if (info[i]) out.push(info[i]);
			}
			if (!out.length) return '';
			return out.join(', ');
		}
	}
}