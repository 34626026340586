<template>
	<router-link :to="{ name: 'setPage', params: { slug: item.slug }}">
		<v-img
			:src="getPhotoPath('set', item.id) + '/' + item.photo + '_180.jpg'"
			:srcset="getPhotoPath('set', item.id) + '/' + item.photo + '_360.jpg 2x'"
			:alt="item.name"
			max-width="180"
			min-width="180"
			eager
		>
			<v-container fill-height text-center>
				<div class="channel-title" v-html="item.name"></div>
			</v-container>
		</v-img>
	</router-link>
</template>
<script>
import { Helper } from "../../mixins/Helper";

export default {
	props: ["item"],
	mixins: [Helper],
	data() {
		return {};
	}
};
</script>
<style scoped>
.v-image .container {
	background: linear-gradient(
		to bottom,
		rgba(0, 0, 0, 0) 0%,
		rgba(0, 0, 0, 0) 50%,
		rgba(0, 0, 0, 1) 100%
	);
	height: 180px;
}

.v-image:hover {
	box-shadow: 3px 3px 7px #777;
}

.v-image {
	box-shadow: 3px 3px 7px #bbb;
}

.channel-title {
	position: absolute;
	bottom: 10px;
	left: 10px;
	width: 160px;
	color: white;
	white-space: normal;
	line-height: 18px;
	font-size: 14px;
}

@media only screen and (max-width: 640px) {
	.container {
		padding: 0px;
	}
}
</style>
