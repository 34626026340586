<template>
	<v-container>
		<v-layout row>
			<Progress :error="error" :loading="loading"></Progress>
			<v-flex xs12>
				<material-card color="info" v-if="set && set.photo">
					<v-flex slot="header">
						<v-layout row px-5 pt-2>
							<div style="flex-basis:200px;">
								<image-dialog
									:image_original="getPhotoPath('set', set.id) + set.photo + '_original.jpg'"
									:title=" set ? set.name : ''"
									:image="getPhotoPath('set', set.id) + set.photo + '_180.jpg'"
									:image2x="getPhotoPath('set', set.id) + set.photo + '_360.jpg'"
								></image-dialog>
							</div>
							<v-flex>
								<h1 class="text-center" v-html="set.name" v-if="set"></h1>
								<div v-if="set.description" class="text-center" v-html="set.description"></div>
								<div v-if="set.count_channel" class="text-center" v-html="`Просмотрено: ${set.count_view} из ${set.count_channel}`"></div>
							</v-flex>
						</v-layout>
					</v-flex>
					<v-layout row>
						<v-flex xs12>
							<channel-list :items="set.channels"></channel-list>
						</v-flex>
					</v-layout>
				</material-card>
			</v-flex>
		</v-layout>
	</v-container>
</template>

<script>
import ChannelList from "../components/channel-list.vue";
import ImageDialog from "../components/helper/image-dialog.vue";
import { Helper } from "../mixins/Helper";

export default {
	mixins: [Helper],
	components: { ChannelList, ImageDialog },
	props: {
		slug: [String]
	},
	data() {
		return {
			loading: false,
			channels: null,
			set: null,
			error: null,
			count_view: 0
		};
	},
	created() {
		document.title = "Подборка";
		this.fetchData();
	},
	methods: {
		fetchData() {
			var t = this;
			t.error = null;
			t.loading = true;

			window.ajax("/api/set/" + t.slug, {}, function(data, error) {
				t.loading = false;
				if (!error) {
					t.set = data.set;
					document.title = "Подборка: " + t.set.name;
				} else {
					t.error = data;
				}
			});
		}
	}
};
</script>
<style scoped>
</style>